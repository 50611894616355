import React from 'react';
import { HashRouter } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { renderRoutes } from 'react-router-config';
import theme from './theme';
import { configureStore } from './store';
import routes from './routes';
import { ScrollReset  } from './components';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mock';
import './assets/scss/index.scss';

const store = configureStore();

const App = () => {
  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <HashRouter  basename="/">
            <ScrollReset />
            {/*<GoogleAnalytics />*/}
            {/* <CookiesNotification />*/}
            {/*<SearchButton/>*/}
            {renderRoutes(routes)}
          </HashRouter>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </StoreProvider>
  );
};

export default App;
