import React, { useState, useRef, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { SearchButton, StartButton } from 'components';
import {
  AppBar,
  Badge,
  Button,
  IconButton,
  Toolbar,
  Hidden,
  Link,
  colors,
  Popper,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ClickAwayListener
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/LockOutlined';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import axios from 'utils/axios';
import useRouter from 'utils/useRouter';
import SearchIcon from '@material-ui/icons/Search';
import { PricingModal, NotificationsPopover } from 'components';
import { logout } from 'actions';
const logo = process.env.PUBLIC_URL + '/images/logos/logo.svg'
const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  barStyle: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
  },
  flexGrow: {
    flexGrow: 1
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 600,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit'
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit'
    }
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100
  },
  searchPopperContent: {
    marginTop: theme.spacing(1)
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  trialIcon: {
    marginRight: theme.spacing(1)
  },
  notificationsButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600]
  },
  logoutButton: {
    marginLeft: theme.spacing(1)
  },
  logoutIcon: {
    marginRight: theme.spacing(1)
  },
  logo: {
    height: 90,
    paddingTop: 15,
    paddingBottom: 15
  },
  logoResponsive: {
    textAlign: 'center',
    width: '100%'
  },
  menuIcon: {
    marginRight: 24
  },
  menuButton: {
    position: 'static'
  },
  margin: {
    margin: theme.spacing(1),
  },
  marginButton: {
    margin: theme.spacing(1),
  }
}));

const TopBar = props => {
  const { onOpenNavBarMobile, className, ...rest } = props;

  const classes = useStyles();
  const { history } = useRouter();
  const searchRef = useRef(null);
  const dispatch = useDispatch();
  const notificationsRef = useRef(null);
  const [pricingModalOpen, setPricingModalOpen] = useState(false);
  const [openSearchPopover, setOpenSearchPopover] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [openNotifications, setOpenNotifications] = useState(false);

  useEffect(() => {
    let mounted = true;

    const fetchNotifications = () => {
      axios.get('/api/account/notifications').then(response => {
        if (mounted) {
          setNotifications(response.data.notifications);
        }
      });
    };

    fetchNotifications();

    return () => {
      mounted = false;
    };
  }, []);

  const handleLogout = () => {
    history.push('/auth/login');
    // dispatch(logout());
  };

  const handlePricingOpen = () => {
    setPricingModalOpen(true);
  };

  const handlePricingClose = () => {
    setPricingModalOpen(false);
  };

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  const handleSearchChange = event => {
    setSearchValue(event.target.value);

    if (event.target.value) {
      if (!openSearchPopover) {
        setOpenSearchPopover(true);
      }
    } else {
      setOpenSearchPopover(false);
    }
  };

  const handleSearchPopverClose = () => {
    setOpenSearchPopover(false);
  };

  const popularSearches = [
    'Devias React Dashboard',
    'Devias',
    'Admin Pannel',
    'Project',
    'Pages'
  ];

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
    >
      <Toolbar className={classes.barStyle}>
        <Hidden mdUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onOpenNavBarMobile}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Hidden mdUp>
          <div
            className={classes.logoResponsive}
          >
            <Link
              to="/home"
              component={RouterLink}
            >
              <img
                className={classes.logo}
                alt="Logo"
                src={logo}
              />
            </Link>
          </div>
          <SearchButton />

          {/*Boton EMPEZAR-------------------------------------------------------------------------*/}
          {/*<StartButton/>}
          {/*--------------------------------------------------------------------------------------*/}
        </Hidden>
        <Hidden smDown>
          <Link
            to="/home"
            component={RouterLink}
          >
            <img
              className={classes.logo}
              alt="Logo"
              src={logo}
            />
          </Link>
        </Hidden>
        <div className={classes.flexGrow} />
        <Hidden smDown>
          <Button
            className={classes.marginButton}
            component={RouterLink}
            color="primary"
            size="large"
            to="/what-do"
          >
            QUÉ HACEMOS
          </Button>
          <Button
            className={classes.marginButton}
            component={RouterLink}
            color="primary"
            size="large"
            to="/how"

          >
            CÓMO LO HACEMOS
          </Button>
          <Button
            className={classes.marginButton}
            component={RouterLink}
            color="primary"
            size="large"
            to="/sectors"
          >
            SECTORES
          </Button>
          <Button
            className={classes.marginButton}
            component={RouterLink}
            color="primary"
            size="large"
            to="/requirements"
          >
            REQUISITOS
          </Button>
          {/*Boton EMPEZAR-------------------------------------------------------------------------*/}
          {/*<StartButton/>}
          {/*--------------------------------------------------------------------------------------*/}
          <SearchButton />
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
