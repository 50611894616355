import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid} from '@material-ui/core';
import CardActionArea from '@material-ui/core/CardActionArea';
import { ProductCard } from './components';
import mockData from './data';
import { Link as RouterLink } from 'react-router-dom';
import { Page } from 'components';
import { Header} from './components';
const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  header: {
    marginBottom: theme.spacing(3)
  },
  card:{
    height: 180
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  imageContainer: {
    height: 64,
    width: 64,
    margin: '0 auto',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '5px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding:5,
  },
  image: {
    width: '100%',
    height:'100%'
  },
  statsItem: {
    display: 'flex',
    alignItems: 'center'
  },
  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1)
  }
}));

const DashboardOld = () => {
  const classes = useStyles();

  const [products] = useState(mockData);

  return (
    <Page
    className={classes.root}
  >
    <Header className={classes.header} />      
    <Grid
          container
          spacing={3}
          direction="row"
          justify="center"
          alignItems="center"
        
        >
            {products.map(product => (
            <Grid
              item
              key={product.id}
              lg={4}
              md={4}
              xs={12}
            >  <CardActionArea 
                component={RouterLink}
                to={product.href} 
                target={product.go} > 
              <ProductCard product={product} />
              </CardActionArea>
            </Grid>
          ))}
        </Grid>
  </Page>
  );
};

export default DashboardOld;
