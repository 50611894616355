import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  addIcon: {
    marginRight: theme.spacing(1)
  },
  titleBox:{
    display: 'flex',
    alignItems: 'center',
  },
  title:{
    fontSize: 25
  },  
}));

const Header = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        alignItems="flex-end"
        container
        justify="space-between"
        spacing={3}
      >
        <Grid item>
          <Typography
            component="h2"
            gutterBottom
            variant="overline"
          >            
            Dashboard            
          </Typography>
          <div className={classes.titleBox}>
            
            <Typography
              component="h1"
              variant="h3"
              className={classes.title}
            >
              Consola de Gestion de Citas
            </Typography>
          </div> 
         
        </Grid>
        {/*<Grid item>
          <Button
            color="primary"
            component={RouterLink}
            to="/projects/create"
            variant="contained"
          >
            <AddIcon className={classes.addIcon} />
            Submit project
          </Button>
        </Grid>*/}
      </Grid>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
