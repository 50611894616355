import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Typography, Grid, Button } from '@material-ui/core';
const naturalPerson = process.env.PUBLIC_URL + '/images/liquidity/natural-person.svg';
const legalPerson = process.env.PUBLIC_URL + '/images/liquidity/legal-person.svg';
const natural = process.env.PUBLIC_URL + '/files/SOLICITUD DE CREDITO PERSONA NATURAL.pdf';
const juridica = process.env.PUBLIC_URL + '/files/SOLICITUD DE CREDITO PERSONA JURIDICA.pdf';


const styles = theme => ({
  root: {},
  buttonContainer: {
    width: theme.breakpoints.values.md,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    }
  },
  imageContainerButton: {
    justifyContent: 'center',
    padding: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    }
  },
  imageIcon: {
    width: 120,
    paddingBottom: 15,
    [theme.breakpoints.down('xs')]: {
      width: 80,
    }
  },
  title: {
    color: '#00a196',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20
    }
  },
});



class AccesButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      autoPlay: true,
      timer: 700,
      animation: 'slide',
      indicators: true,
      timeout: 700,
      navButtonsAlwaysVisible: false,
      navButtonsAlwaysInvisible: true,
    }

  }

  render() {
    const { classes } = this.props;
    return (
      <div
        className={clsx(classes.root)}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid
            item
            lg={4}
            md={4}
            xs={4}
            sm={4}
          >
            <Button
              className={classes.buttonContainer}
              href={natural}
              target="_blank"
            >
              <div
                className={classes.imageContainerButton}
              >
                <img
                  className={classes.imageIcon}
                  alt='Persona Natural'
                  src={naturalPerson}
                />
                <Typography
                  align="center"
                  variant="h3"
                  className={classes.title}
                >
                  PERSONA
                          </Typography>
                <Typography
                  align="center"
                  variant="h3"
                  className={classes.title}
                >
                  NATURAL
                </Typography>
              </div>
            </Button>
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            xs={4}
            sm={4}
          >
            <Button
              className={classes.buttonContainer}
              href={juridica}
              target="_blank"
            >
              <div
                className={classes.imageContainerButton}
              >
                <img
                  className={classes.imageIcon}
                  alt='Persona Jurídica'
                  src={legalPerson}
                />
                <Typography
                  align="center"
                  variant="h3"
                  className={classes.title}
                >
                  PERSONA
                </Typography>
                <Typography
                  align="center"
                  variant="h3"
                  className={classes.title}
                >
                  JURÍDICA
                </Typography>
              </div>
            </Button>
          </Grid>
          {/*<Grid
            item
            lg={4}
            md={4}
            xs={4}
            sm={4}
          >
            <Button
              className={classes.buttonContainer}
            >
              <div
                className={classes.imageContainerButton}
              >
                <img
                  className={classes.imageIcon}
                  alt='Haz tu Cita'
                  src={quotes}
                />
                <Typography
                  align="center"
                  variant="h3"
                  className={classes.title}
                >
                  HAZ TU
                          </Typography>
                <Typography
                  align="center"
                  variant="h3"
                  className={classes.title}
                >
                  CITA
                          </Typography>
              </div>
            </Button>
          </Grid>*/}
        </Grid>
      </div>
    )
  }
}
AccesButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AccesButton);
