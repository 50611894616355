import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import CloseIcon from '@material-ui/icons/Close';
import MaskedInput from 'react-text-mask';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Fab from "@material-ui/core/Fab";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import ImageUploader from "react-images-upload";
import { DropzoneArea } from 'material-ui-dropzone'
import {
  AppBar,
  Badge,
  Button,
  IconButton,
  Grid,
  Hidden,
  Typography,
  TextField,
  Popper,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ClickAwayListener
} from '@material-ui/core';
const naturalPerson = process.env.PUBLIC_URL + '/images/liquidity/natural-person.svg';
const legalPerson = process.env.PUBLIC_URL + '/images/liquidity/legal-person.svg';



const styles = theme => ({
  root: {},
  marginButton: {
    margin: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
  contenMenu: {
    paddingBottom: 24
  },
  imageIcon: {
    width: 120,
    paddingBottom: 15,
    [theme.breakpoints.down('xs')]: {
      width: 80,
    }
  },
  inputContainer: {
    height: 94
  },
  title: {
    color: '#00a196',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20
    }
  },
  buttonContainer: {
    width: theme.breakpoints.values.md,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    }
  },
  gridMenu: {
    height: '100%'
  },
  alertColor: {
    backgroundColor: '#ffa000'
  },
  alertColorSucces: {
    backgroundColor: '#43a047'
  },
  alertContentSucces: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 12
  },
  alertContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 12
  },
  icon: {
    color: '#FFF',
    margin: 12
  },
  textAlert: {
    paddingBottom: 0,
    paddingLeft: 12,
    paddingRight: 12,
    color: '#FFF'
  },
  input: {
    display: "none"
  },
  centerControl: {
    textAlign: 'center'
  },
  dialogTextUp: {
    paddingBottom: 13, 
    paddingTop: 24,
    paddingLeft: 24,
    paddingRight: 24
  },
  imageIconDialog: {
    width: 80,
    paddingBottom: 5,
    [theme.breakpoints.down('xs')]: {
      width: 40,
    }
  },
  dialogUpContainer: {
    textAlign: 'center', paddingBottom: 15, paddingTop: 15,
  },
  imageBox: {
    width: '100%',
    height: 200,
    borderStyle: 'dotted',
    borderWidth: 3,
    borderColor: '#c1bfbf',
    marginTop: 8
  },
  imageInput: {
    width: '100%',
    height: '100%',
  },
  button: {
    color: '#FFF !important',
    backgroundColor: '#009840 !important',
    margin: 10
  },
  backButton: {
    color: '#FFF !important',
    backgroundColor: '#58585a !important',
    width: 90
  },
  actionBox: {
    justifyContent: 'center !important'
  },
  nextButton: {
    color: '#FFF !important',
    backgroundColor: '#009840 !important',
    width: 90
  }
});

/**
 * Returns a promise that reads a file as a dataUrl representation (composed
 * of a mimetype and a base64 string).
 *
 * @param file {File}
 * @return {Promise}
 */
async function readDataUrl(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    // async event handlers
    reader.onload = e => resolve(reader.result);
    reader.onerror = e => reject(reader.error);
    reader.readAsDataURL(file);
  });
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function TextMaskTelefono(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholder={'0000-0000'}
      guide={false}
    />
  );
}
TextMaskTelefono.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function TextMaskDUI(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/]}
      placeholder={'00000000-0'}
      guide={false}
    />
  );
}
TextMaskDUI.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
function TextMaskNIT(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/]}
      placeholder={'0000-000000-000-0'}
      guide={false}
    />
  );
}
TextMaskNIT.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
function TextMaskNRC(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/]}
      placeholder={'000000-0'}
      guide={false}
    />
  );
}
TextMaskNRC.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
const emailRegex = RegExp(
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/
);
class StartButton extends Component {

  constructor() {
    super();
    this.state = {
      nombreNatural: '',
      apellidoNatural: '',
      duiNatural: '',
      nitNatural: '',
      nrcNatural: '',
      telefonoNatural: '',
      emailNatural: '',
      nombreJuridica: '',
      nombreLegalJuridica: '',
      nombreContactoJuridica: '',
      nitJuridica: '',
      nrcJuridica: '',
      telefonoJuridica: '',
      emailJuridica: '',
      scroll: 'body',
      file: null,
      base64DUIFrontal: null,
      base64DUITrasero: null,
      base64NITFotoNatural: null,
      base64NRCFotoNatural: null,
      base64NITFotoJuridica: null,
      base64NRCFotoJuridica: null,
      objectUrl: null,
      DUIFrontal: [],
      DUITrasero: [],
      NITFotoNatural: [],
      NRCFotoNatural: [],
      NITFotoJuridica: [],
      NRCFotoJuridica: [],
      errors: {
        emailNatural: '',
        emailNaturalValid: false,
        emailJuridica: '',
        emailJuridicaValid: false
      },
      openMenu: false,
      openNatural: false,
      openJuridica: false,
      openAlertSuccess: false,
      openAlertWarningNatural: false,
      openAlertWarningJuridica: false,
    };
    this.handleClickAlertSuccess = this.handleClickAlertSuccess.bind(this);
    this.handleCloseAlertSuccess = this.handleCloseAlertSuccess.bind(this);
    this.handleClickAlertWarningNatural = this.handleClickAlertWarningNatural.bind(this);
    this.handleCloseAlertWarningNatural = this.handleCloseAlertWarningNatural.bind(this);
    this.handleClickAlertWarningJuridica = this.handleClickAlertWarningJuridica.bind(this);
    this.handleClickAlertWarningJuridica = this.handleClickAlertWarningJuridica.bind(this);

  }

  /*Foto DUI FRONTAL NATURAL--------------------------------------------------------------*/
  handleChangePhotoFileInputDuiFrontal = e => {
    const target = e.currentTarget;
    const file = target.files.item(0);

    // validate file as image
    if (!file.type.startsWith("image/")) {
      alert("File is not an image");
      return;
    }

    // store reference to the File object and a base64 representation of it
    readDataUrl(file).then(dataUrl => {
      this.setState({
        ...this.state,
        file,
        base64DUIFrontal: dataUrl,
        objectUrl: URL.createObjectURL(file)
      });
    });
  };
  handleChangeDUIFrontal(files) {
    this.setState({
      DUIFrontal: files
    });
  }
  /*--------------------------------------------------------------------------------------*/
  /*Foto DUI TRASERO NATURAL--------------------------------------------------------------*/
  handleChangePhotoFileInputDuiTrasero = e => {
    const target = e.currentTarget;
    const file = target.files.item(0);

    // validate file as image
    if (!file.type.startsWith("image/")) {
      alert("File is not an image");
      return;
    }

    // store reference to the File object and a base64 representation of it
    readDataUrl(file).then(dataUrl => {
      this.setState({
        ...this.state,
        file,
        base64DUITrasero: dataUrl,
        objectUrl: URL.createObjectURL(file)
      });
    });
  };
  handleChangeDUITrasero(files) {
    this.setState({
      DUITrasero: files
    });
  }
  /*--------------------------------------------------------------------------------------*/
  /*Foto NIT NATURAL----------------------------------------------------------------------*/
  handleChangePhotoFileInputNitFotoNatural = e => {
    const target = e.currentTarget;
    const file = target.files.item(0);

    // validate file as image
    if (!file.type.startsWith("image/")) {
      alert("File is not an image");
      return;
    }

    // store reference to the File object and a base64 representation of it
    readDataUrl(file).then(dataUrl => {
      this.setState({
        ...this.state,
        file,
        base64NITFotoNatural: dataUrl,
        objectUrl: URL.createObjectURL(file)
      });
    });
  };
  handleChangeNITFotoNatural(files) {
    this.setState({
      NITFotoNatural: files
    });
  }
  /*--------------------------------------------------------------------------------------*/
  /*Foto NRC NATURAL----------------------------------------------------------------------*/
  handleChangePhotoFileInputNrcFotoNatural = e => {
    const target = e.currentTarget;
    const file = target.files.item(0);

    // validate file as image
    if (!file.type.startsWith("image/")) {
      alert("File is not an image");
      return;
    }

    // store reference to the File object and a base64 representation of it
    readDataUrl(file).then(dataUrl => {
      this.setState({
        ...this.state,
        file,
        base64NRCFotoNatural: dataUrl,
        objectUrl: URL.createObjectURL(file)
      });
    });
  };
  handleChangeNRCFotoNatural(files) {
    this.setState({
      NRCFotoNatural: files
    });
  }
  /*--------------------------------------------------------------------------------------*/
  /*Foto NIT JURIDICA---------------------------------------------------------------------*/
  handleChangePhotoFileInputNitFotoJuridica = e => {
    const target = e.currentTarget;
    const file = target.files.item(0);

    // validate file as image
    if (!file.type.startsWith("image/")) {
      alert("File is not an image");
      return;
    }

    // store reference to the File object and a base64 representation of it
    readDataUrl(file).then(dataUrl => {
      this.setState({
        ...this.state,
        file,
        base64NITFotoJuridica: dataUrl,
        objectUrl: URL.createObjectURL(file)
      });
    });
  };
  handleChangeNITFotoJuridica(files) {
    this.setState({
      NITFotoJuridica: files
    });
  }
  /*--------------------------------------------------------------------------------------*/
  /*Foto NRC JURIDICA---------------------------------------------------------------------*/
  handleChangePhotoFileInputNrcFotoJuridica = e => {
    const target = e.currentTarget;
    const file = target.files.item(0);

    // validate file as image
    if (!file.type.startsWith("image/")) {
      alert("File is not an image");
      return;
    }

    // store reference to the File object and a base64 representation of it
    readDataUrl(file).then(dataUrl => {
      this.setState({
        ...this.state,
        file,
        base64NRCFotoJuridica: dataUrl,
        objectUrl: URL.createObjectURL(file)
      });
    });
  };
  handleChangeNRCFotoJuridica(files) {
    this.setState({
      NRCFotoJuridica: files
    });
  }
  /*--------------------------------------------------------------------------------------*/
  /*VALIDAR CORREOS-----------------------------------------------------------------------*/
  handleChangeEmailNatural = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case 'emailNatural':
        errors.emailNatural =
          emailRegex.test(value)
            ? ''
            : 'El formato del correo electrónico es incorrecto.';
        errors.emailNaturalValid =
          emailRegex.test(value)
            ? false
            : true;
        break;
      case 'emailJuridica':
        errors.emailJuridica =
          emailRegex.test(value)
            ? ''
            : 'El formato del correo electrónico es incorrecto.';
        errors.emailJuridicaValid =
          emailRegex.test(value)
            ? false
            : true;
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });
  }
  /*--------------------------------------------------------------------------------------*/
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChangeInput = event => {
    let target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    this.setState({
      [name]: value
    });
  }
  /*Modal Alert Success handle------------------------------------------------------------*/
  handleClickAlertSuccess = () => {
    this.setState({ openAlertSuccess: true });
  };
  handleCloseAlertSuccess = () => {
    this.setState({ openAlertSuccess: false });
  };
  /*--------------------------------------------------------------------------------------*/
  /*Modal Alert Warning Natural handle----------------------------------------------------*/
  handleClickAlertWarningNatural = () => {
    this.setState({ openAlertWarningNatural: true });
  };
  handleCloseAlertWarningNatural = () => {
    this.setState({ openAlertWarningNatural: false });
  };
  /*--------------------------------------------------------------------------------------*/
  /*Modal Alert Warning Juridica handle---------------------------------------------------*/
  handleClickAlertWarningJuridica = () => {
    this.setState({ openAlertWarningJuridica: true });
  };
  handleCloseAlertWarningJuridica = () => {
    this.setState({ openAlertWarningJuridica: false });
  };
  /*--------------------------------------------------------------------------------------*/
  /*Modal Menu handle---------------------------------------------------------------------*/
  handleClickOpenMenu = () => {
    this.setState({ openMenu: true });
  };
  handleCloseMenu = () => {
    this.setState({ openMenu: false });
  };
  /*--------------------------------------------------------------------------------------*/
  /*Modal Persona Natural handle----------------------------------------------------------*/
  handleClickOpenNatural = () => {
    this.setState({ openMenu: false });
    this.setState({ openNatural: true });
  };
  handleClickNaturalMenu = () => {
    this.setState({ openNatural: false });
    this.setState({ openMenu: true });
  };
  handleCloseNatural = () => {
    this.setState({ openNatural: false });
  };
  /*--------------------------------------------------------------------------------------*/
  /*Modal Persona Jurídica handle---------------------------------------------------------*/
  handleClickOpenJuridica = () => {
    this.setState({ openMenu: false });
    this.setState({ openJuridica: true });
  };
  handleClickJuridicaMenu = () => {
    this.setState({ openJuridica: false });
    this.setState({ openMenu: true });
  };
  handleCloseJuridica = () => {
    this.setState({ openJuridica: false });
  };
  /*--------------------------------------------------------------------------------------*/
  /*Envio de Datos Persona Natural--------------------------------------------------------*/
  handlePersonaNatural = () => {
    console.log('DUI Natural ', this.state.duiNatural.toUpperCase);
    console.log('NIT Natural ', this.state.nitNatural.length);
    console.log('NRC Natural ', this.state.nrcNatural.length);
    console.log('DUI FRONTAL ', this.state.DUIFrontal[0]);
    if (this.state.telefonoNatural.length < 9 /*|| this.state.NRCFotoNatural[0] == null || this.state.NITFotoNatural[0] == null || this.state.DUITrasero[0] == null || this.state.DUIFrontal[0] == null*/ || this.state.duiNatural.length < 11 || this.state.nitNatural.length < 17 || this.state.nrcNatural.length < 8 || this.state.nombreNatural.trim() == "" || this.state.apellidoNatural.trim() == "" || this.state.duiNatural.trim() == "" || this.state.nitNatural.trim() == "" || this.state.nrcNatural.trim() == "" || this.state.telefonoNatural.trim() == "" || this.state.errors.emailNatural > 0 || this.state.errors.emailNatural == 'El formato del correo electrónico es incorrecto.' || this.state.emailNatural.trim() == "") {



      // if (this.state.NRCFotoNatural[0] == null) {
      //   this.setState({ msg: 'No olvides agregar la fotografía de tu NRC' });
      //   this.handleClickAlertWarningNatural();
      // }
      // if (this.state.NITFotoNatural[0] == null) {
      //   this.setState({ msg: 'No olvides agregar la fotografía de tu NIT' });
      //   this.handleClickAlertWarningNatural();
      // }
      // if (this.state.DUITrasero[0] == null) {
      //   this.setState({ msg: 'No olvides agregar la fotografía de la parte trasera de tu DUI' });
      //   this.handleClickAlertWarningNatural();
      // }
      // if (this.state.DUIFrontal[0] == null) {
      //   this.setState({ msg: 'No olvides agregar la fotografía de la parte delantera de tu DUI' });
      //   this.handleClickAlertWarningNatural();
      // }
      if (this.state.errors.emailNatural > 0) {
        this.setState({ msg: 'Verifica el formato del correo electrónico' });
        this.handleClickAlertWarningJuridica();
      }
      if (this.state.errors.emailNatural == 'El formato del correo electrónico es incorrecto.') {
        this.setState({ msg: 'Verifica el formato del correo electrónico' });
        this.handleClickAlertWarningNatural();
      }

      if (this.state.emailNatural.trim() == "") {
        this.setState({ msg: 'No olvides ingresar tu correo electrónico' });
        this.handleClickAlertWarningNatural();
      }
      if (this.state.telefonoNatural.length < 9) {
        this.setState({ msg: 'Completa tu número de teléfono.' });
        this.handleClickAlertWarningJuridica();
      }
      if (this.state.telefonoNatural.trim() == "") {
        this.setState({ msg: 'No olvides ingresar tu teléfono' });
        this.handleClickAlertWarningNatural();
      }
      if (this.state.nrcNatural.length < 8) {
        this.setState({ msg: 'Completa tu número de NRC' });
        this.handleClickAlertWarningNatural();
      }
      if (this.state.nrcNatural.trim() == "") {
        this.setState({ msg: 'No olvides ingresar tu NRC' });
        this.handleClickAlertWarningNatural();
      }
      if (this.state.nitNatural.length < 17) {
        this.setState({ msg: 'Completa tu número tu NIT' });
        this.handleClickAlertWarningNatural();
      }
      if (this.state.nitNatural.trim() == "") {
        this.setState({ msg: 'No olvides ingresar tu NIT' });
        this.handleClickAlertWarningNatural();
      }
      if (this.state.duiNatural.length < 11) {
        this.setState({ msg: 'Completa tu número de DUI' });
        this.handleClickAlertWarningNatural();
      }
      if (this.state.duiNatural.trim() == "") {
        this.setState({ msg: 'No olvides ingresar tu DUI.' });
        this.handleClickAlertWarningNatural();
      }
      if (this.state.apellidoNatural.trim() == "") {
        this.setState({ msg: 'No olvides ingresar tu apellido' });
        this.handleClickAlertWarningNatural();
      }

      if (this.state.nombreNatural.trim() == "") {
        this.setState({ msg: 'No olvides ingresar tu nombre' });
        this.handleClickAlertWarningNatural();
      }
    } else {
      this.handleCloseNatural();
      this.handleClickAlertSuccess();

    }
  };
  /*--------------------------------------------------------------------------------------*/
  /*Envio de Datos Persona Juridica-------------------------------------------------------*/
  handlePersonaJuridica = () => {
    console.log('NIT Natural ', this.state.nitJuridica.length);
    console.log('NRC Natural ', this.state.nrcJuridica.length);
    console.log('DUI Frontal ', this.state.DUIFrontal);
    if (this.state.nombreContactoJuridica.trim() == "" || this.state.telefonoJuridica.length < 9 || /*this.state.NRCFotoJuridica[0] == null || this.state.NITFotoJuridica[0] == null ||*/ this.state.nitJuridica.length < 17 || this.state.nrcJuridica.length < 8 || this.state.nombreJuridica.trim() == "" || this.state.nombreLegalJuridica.trim() == "" || this.state.nitJuridica.trim() == "" || this.state.nrcJuridica.trim() == "" || this.state.telefonoJuridica.trim() == "" || this.state.errors.emailJuridica > 0 || this.state.errors.emailJuridica == 'El formato del correo electrónico es incorrecto.' || this.state.emailJuridica.trim() == "") {

      //if (this.state.NRCFotoJuridica[0] == null) {
      //  this.setState({ msg: 'No olvides agregar la fotografía de el NRC de la sociedad' });
      //  this.handleClickAlertWarningJuridica();
      //}
      //if (this.state.NITFotoJuridica[0] == null) {
      // this.setState({ msg: 'No olvides agregar la fotografía de el NIT de la sociedad' });
      //  this.handleClickAlertWarningJuridica();
      //}

      if (this.state.errors.emailJuridica > 0) {
        this.setState({ msg: 'Verifica el formato del correo electrónico' });
        this.handleClickAlertWarningJuridica();
      }

      if (this.state.errors.emailJuridica == 'El formato del correo electrónico es incorrecto.') {
        this.setState({ msg: 'Verifica el formato del correo electrónico' });
        this.handleClickAlertWarningJuridica();
      }

      if (this.state.emailJuridica.trim() == "") {
        this.setState({ msg: 'No olvides ingresar tu correo electrónico de contacto' });
        this.handleClickAlertWarningJuridica();
      }
      if (this.state.telefonoJuridica.length < 9) {
        this.setState({ msg: 'Completa tu número de teléfono.' });
        this.handleClickAlertWarningJuridica();
      }
      if (this.state.telefonoJuridica.trim() == "") {
        this.setState({ msg: 'No olvides ingresar tu teléfono de contacto' });
        this.handleClickAlertWarningJuridica();
      }
      if (this.state.nrcJuridica.length < 8) {
        this.setState({ msg: 'Completa el número de NRC de la sociedad' });
        this.handleClickAlertWarningJuridica();
      }
      if (this.state.nrcJuridica.trim() == "") {
        this.setState({ msg: 'No olvides ingresar el NRC de la sociedad' });
        this.handleClickAlertWarningJuridica();
      }
      if (this.state.nitJuridica.length < 17) {
        this.setState({ msg: 'Completa el número de NIT de la sociedad' });
        this.handleClickAlertWarningJuridica();
      }
      if (this.state.nitJuridica.trim() == "") {
        this.setState({ msg: 'No olvides ingresar el NIT de la sociedad' });
        this.handleClickAlertWarningJuridica();
      }
      if (this.state.nombreContactoJuridica.trim() == "") {
        this.setState({ msg: 'No olvides completar el nombre del contacto' });
        this.handleClickAlertWarningJuridica();
      }
      if (this.state.nombreLegalJuridica.trim() == "") {
        this.setState({ msg: 'No olvides completar el nombre del representante legal' });
        this.handleClickAlertWarningJuridica();
      }

      if (this.state.nombreJuridica.trim() == "") {
        this.setState({ msg: 'No olvides completar el nombre de la sociedad' });
        this.handleClickAlertWarningJuridica();
      }
    } else {
      this.handleCloseJuridica();
      this.handleClickAlertSuccess();

    }
  };
  /*--------------------------------------------------------------------------------------*/
  render() {
    const { base64DUIFrontal, base64DUITrasero, base64NITFotoNatural, base64NRCFotoNatural, base64NITFotoJuridica, base64NRCFotoJuridica } = this.state;
    const defaultImage = process.env.PUBLIC_URL + '/images/placeholder.jpg';
    const { classes } = this.props;
    const { fullScreen } = this.props;
    return (

      <div>
        {/*Alert Success ------------------------------------------------------------------*/}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={this.state.openAlertSuccess}
          autoHideDuration={6000}
          onClose={this.handleCloseAlertSuccess}
        >
          <Alert onClose={this.handleCloseAlertSuccess} severity="success">
            Hemos recibido tu solicitud inicial. Unos de nuestros asesores comerciales te contactará pronto.
        </Alert>
        </Snackbar>
        {/*--------------------------------------------------------------------------------*/}
        {/*Vista Movil---------------------------------------------------------------------*/}
        <Hidden mdUp>
          <Button variant="contained" size="small" color="primary" onClick={this.handleClickOpenMenu} className={classes.marginButton}>
            EMPEZAR
          </Button>
        </Hidden>
        {/*--------------------------------------------------------------------------------*/}
        {/*Vista Escritorio----------------------------------------------------------------*/}
        <Hidden smDown>
          <Button
            className={classes.marginButton}
            onClick={this.handleClickOpenMenu}
            color="primary"
            size="large"
            variant="contained"
          >
            EMPEZAR
          </Button>
        </Hidden>
        {/*--------------------------------------------------------------------------------*/}
        {/*Modal Menu----------------------------------------------------------------------*/}
        <Dialog
          fullScreen={fullScreen}
          open={this.state.openMenu}
          onClose={this.handleCloseMenu}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
        >

          <Typography
            align="center"
            variant="h5"
            className={classes.dialogTextUp}
          >
            Selecciona la solicitud inicial de acuerdo a tu personería jurídica
          </Typography>
          <IconButton aria-label="Close" size="small" className={classes.closeButton} onClick={this.handleCloseMenu}>
            <CloseIcon />
          </IconButton>
          <DialogContent className={classes.contenMenu}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              className={classes.gridMenu}
            >
              <Grid
                item
                lg={6}
                md={6}
                xs={12}
                sm={12}
              >
                <Button
                  className={classes.buttonContainer}
                  onClick={this.handleClickOpenNatural}
                >
                  <div
                    className={classes.imageContainerButton}
                  >
                    <img
                      className={classes.imageIcon}
                      alt='Persona Natural'
                      src={naturalPerson}
                    />
                    <Typography
                      align="center"
                      variant="h3"
                      className={classes.title}
                    >
                      PERSONA
                    </Typography>
                    <Typography
                      align="center"
                      variant="h3"
                      className={classes.title}
                    >
                      NATURAL
                    </Typography>
                  </div>
                </Button>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                xs={12}
                sm={12}
              >
                <Button
                  className={classes.buttonContainer}
                  onClick={this.handleClickOpenJuridica}
                >
                  <div
                    className={classes.imageContainerButton}
                  >
                    <img
                      className={classes.imageIcon}
                      alt='Persona Jurídica'
                      src={legalPerson}
                    />
                    <Typography
                      align="center"
                      variant="h3"
                      className={classes.title}
                    >
                      PERSONA
                    </Typography>
                    <Typography
                      align="center"
                      variant="h3"
                      className={classes.title}
                    >
                      JURÍDICA
                    </Typography>
                  </div>
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        {/*--------------------------------------------------------------------------------------*/}
        {/*Modal Persona Natural----------------------------------------------------------------------------*/}
        <Dialog
          fullScreen={fullScreen}
          open={this.state.openNatural}
          onClose={this.handleCloseNatural}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          scroll={this.state.scroll}
        >
          {/*Alert Warning ------------------------------------------------------------------*/}
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            open={this.state.openAlertWarningNatural}
            autoHideDuration={6000}
            onClose={this.handleCloseAlertWarningNatural}
          >
            <Alert onClose={this.handleCloseAlertWarningNatural} severity="warning">
              {this.state.msg}
            </Alert>
          </Snackbar>
          {/*--------------------------------------------------------------------------------*/}
          <div
            className={classes.dialogUpContainer}
          >
            <img
              className={classes.imageIconDialog}
              alt='Persona Natural'
              src={naturalPerson}
            />
            <Typography
              align="center"
              variant="h3"
              className={classes.title}
            >
              PERSONA NATURAL
            </Typography>
          </div>
          <IconButton aria-label="Close" size="small" className={classes.closeButton} onClick={this.handleCloseNatural}>
            <CloseIcon />
          </IconButton>

          <DialogContent>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={2}
            >
              <Grid
                className={classes.inputContainer}
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
              >
                <TextField
                  fullWidth
                  label="NOMBRE"
                  name="nombreNatural"
                  value={this.state.nombreNatural}
                  onChange={this.handleChangeInput}
                  required
                  variant="outlined"
                  placeholder="NOMBRE"
                  inputProps={{ maxLength: 50, style: { textTransform: 'uppercase' } }}
                />
              </Grid>
              <Grid
                className={classes.inputContainer}
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
              >
                <TextField
                  fullWidth
                  label="APELLIDO"
                  name="apellidoNatural"
                  value={this.state.apellidoNatural}
                  onChange={this.handleChangeInput}
                  required
                  variant="outlined"
                  placeholder="APELLIDO"
                  inputProps={{ maxLength: 50, style: { textTransform: 'uppercase' } }}
                />
              </Grid>
              <Grid
                className={classes.inputContainer}
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
              >

                <TextField
                  fullWidth
                  label="DUI"
                  name="duiNatural"
                  value={this.state.duiNatural}
                  onChange={this.handleChangeInput}
                  required
                  variant="outlined"
                  InputProps={{
                    inputComponent: TextMaskDUI
                  }}
                />
              </Grid>
              <Grid
                className={classes.inputContainer}
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
              >
                <TextField
                  fullWidth
                  label="NIT"
                  name="nitNatural"
                  value={this.state.nitNatural}
                  onChange={this.handleChangeInput}
                  required
                  variant="outlined"
                  InputProps={{
                    inputComponent: TextMaskNIT
                  }}
                />
              </Grid>
              <Grid
                className={classes.inputContainer}
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
              >
                <TextField
                  fullWidth
                  label="NRC"
                  name="nrcNatural"
                  value={this.state.nrcNatural}
                  onChange={this.handleChangeInput}
                  required
                  variant="outlined"
                  InputProps={{
                    inputComponent: TextMaskNRC
                  }}
                />
              </Grid>
              <Grid
                className={classes.inputContainer}
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  label="TELÉFONO*"
                  id="telefono"
                  name="telefonoNatural"
                  value={this.state.telefonoNatural}
                  onChange={this.handleChangeInput}
                  InputProps={{
                    inputComponent: TextMaskTelefono
                  }}
                />
              </Grid>
              <Grid
                className={classes.inputContainer}
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <TextField
                  fullWidth
                  label="CORREO ELECTRÓNICO"
                  placeholder="correo@dominio.com"
                  type="email"
                  name="emailNatural"
                  value={this.state.emailNatural}
                  inputProps={{ maxLength: 70, style: { textTransform: 'lowercase' } }}
                  noValidate
                  onChange={this.handleChangeEmailNatural}
                  required
                  variant="outlined"
                  error={this.state.errors.emailNaturalValid}
                  helperText={this.state.errors.emailNatural}
                />
              </Grid>
              <Grid
                className={classes.centerControl}
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
              >
                <Typography
                  align="center"
                  variant="h3"
                  className={classes.title}
                >
                  DUI FRONTAL
                </Typography>
                <Typography
                  align="center"
                  variant="caption"
                  className={classes.title}
                  gutterBottom
                >
                  Anexa la fotografía de la parte frontal de tu DUI
                </Typography>
                <div className={classes.imageBox}>
                  <img
                    className={classes.imageInput}
                    src={base64DUIFrontal || defaultImage}
                    ref={img => (this.imgRef = img)}
                  />
                </div>

                <input
                  onChange={this.handleChangePhotoFileInputDuiFrontal}
                  ref={input => (this.inputFileRef = input)}
                  style={{ display: "none" }}
                  type="file"
                  id="dui-frontal"
                />
                <label htmlFor="dui-frontal">
                  <IconButton color="primary" className={classes.button} aria-label="subir dui frontal" component="span">
                    <AddPhotoAlternateIcon />
                  </IconButton>
                </label>



              </Grid>
              <Grid
                className={classes.centerControl}
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
              >
                <Typography
                  align="center"
                  variant="h3"
                  className={classes.title}
                >
                  DUI TRASERO
                </Typography>
                <Typography
                  align="center"
                  variant="caption"
                  className={classes.title}
                  gutterBottom
                >
                  Anexa la fotografía de la parte trasera de tu DUI
                </Typography>
                <div className={classes.imageBox}>
                  <img
                    className={classes.imageInput}
                    src={base64DUITrasero || defaultImage}
                    ref={img => (this.imgRef = img)}
                  />
                </div>

                <input
                  onChange={this.handleChangePhotoFileInputDuiTrasero}
                  ref={input => (this.inputFileRef = input)}
                  style={{ display: "none" }}
                  type="file"
                  id="dui-trasera"
                />
                <label htmlFor="dui-trasera">
                  <IconButton color="primary" className={classes.button} aria-label="subir dui trasero" component="span">
                    <AddPhotoAlternateIcon />
                  </IconButton>
                </label>


              </Grid>
              <Grid
                className={classes.centerControl}
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
              >
                <Typography
                  align="center"
                  variant="h3"
                  className={classes.title}
                >
                  NIT
                </Typography>
                <Typography
                  align="center"
                  variant="caption"
                  className={classes.title}
                  gutterBottom
                >
                  Anexa la fotografía de tu NIT
                </Typography>
                <div className={classes.imageBox}>
                  <img
                    className={classes.imageInput}
                    src={base64NITFotoNatural || defaultImage}
                    ref={img => (this.imgRef = img)}
                  />
                </div>

                <input
                  onChange={this.handleChangePhotoFileInputNitFotoNatural}
                  ref={input => (this.inputFileRef = input)}
                  style={{ display: "none" }}
                  type="file"
                  id="nit-natural"
                />
                <label htmlFor="nit-natural">
                  <IconButton color="primary" className={classes.button} aria-label="subir nit" component="span">
                    <AddPhotoAlternateIcon />
                  </IconButton>
                </label>

              </Grid>
              <Grid
                className={classes.centerControl}
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
              >
                <Typography
                  align="center"
                  variant="h3"
                  className={classes.title}
                >
                  NRC
                </Typography>
                <Typography
                  align="center"
                  variant="caption"
                  className={classes.title}
                  gutterBottom
                >
                  Anexa la fotografía de tu NRC
                </Typography>
                <div className={classes.imageBox}>
                  <img
                    className={classes.imageInput}
                    src={base64NRCFotoNatural || defaultImage}
                    ref={img => (this.imgRef = img)}
                  />
                </div>

                <input
                  onChange={this.handleChangePhotoFileInputNrcFotoNatural}
                  ref={input => (this.inputFileRef = input)}
                  style={{ display: "none" }}
                  type="file"
                  id="nrc-natural"
                />
                <label htmlFor="nrc-natural">
                  <IconButton color="primary" className={classes.button} aria-label="subir nit" component="span">
                    <AddPhotoAlternateIcon />
                  </IconButton>
                </label>

              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.actionBox}>
            <Button className={classes.backButton} onClick={this.handleClickNaturalMenu} color="primary">
              Regresar
            </Button>
            <Button className={classes.nextButton} onClick={this.handlePersonaNatural} color="primary">
              Enviar
            </Button>
          </DialogActions>
        </Dialog>
        {/*--------------------------------------------------------------------------------------*/}
        {/*Modal Persona Jurídica----------------------------------------------------------------*/}
        <Dialog
          fullScreen={fullScreen}
          open={this.state.openJuridica}
          onClose={this.handleCloseJuridica}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          scroll={this.state.scroll}
        >
          {/*Alert Warning ------------------------------------------------------------------*/}
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            open={this.state.openAlertWarningJuridica}
            autoHideDuration={6000}
            onClose={this.handleCloseAlertWarningJuridica}
          >
            <Alert onClose={this.handleCloseAlertWarningJuridica} severity="warning">
              {this.state.msg}
            </Alert>
          </Snackbar>
          {/*--------------------------------------------------------------------------------*/}
          <div
            className={classes.dialogUpContainer}
          >
            <img
              className={classes.imageIconDialog}
              alt='Persona Jurídica'
              src={legalPerson}
            />
            <Typography
              align="center"
              variant="h3"
              className={classes.title}
            >
              PERSONA JURÍDICA
            </Typography>
          </div>
          <IconButton aria-label="Close" size="small" className={classes.closeButton} onClick={this.handleCloseJuridica}>
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={1}
            >
              <Grid
                className={classes.inputContainer}
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <TextField
                  fullWidth
                  label="NOMBRE DE LA SOCIEDAD"
                  name="nombreJuridica"
                  value={this.state.nombreJuridica}
                  onChange={this.handleChangeInput}
                  required
                  variant="outlined"
                  placeholder="NOMBRE DE LA SOCIEDAD"
                  inputProps={{ maxLength: 70, style: { textTransform: 'uppercase' } }}
                />
              </Grid>
              <Grid
                className={classes.inputContainer}
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <TextField
                  fullWidth
                  label="NOMBRE DEL REPRESENTANTE LEGAL"
                  name="nombreLegalJuridica"
                  value={this.state.nombreLegalJuridica}
                  onChange={this.handleChangeInput}
                  required
                  variant="outlined"
                  placeholder="NOMBRE DEL REPRESENTANTE LEGAL"
                  inputProps={{ maxLength: 70, style: { textTransform: 'uppercase' } }}
                />
              </Grid>
              <Grid
                className={classes.inputContainer}
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <TextField
                  fullWidth
                  label="NOMBRE DE CONTACTO"
                  name="nombreContactoJuridica"
                  value={this.state.nombreContactoJuridica}
                  onChange={this.handleChangeInput}
                  required
                  variant="outlined"
                  placeholder="NOMBRE DE CONTACTO"
                  inputProps={{ maxLength: 70, style: { textTransform: 'uppercase' } }}
                />
              </Grid>
              <Grid
                className={classes.inputContainer}
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
              >
                <TextField
                  fullWidth
                  label="NIT DE LA SOCIEDAD"
                  name="nitJuridica"
                  value={this.state.nitJuridica}
                  onChange={this.handleChangeInput}
                  required
                  variant="outlined"
                  InputProps={{
                    inputComponent: TextMaskNIT
                  }}
                />
              </Grid>
              <Grid
                className={classes.inputContainer}
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
              >
                <TextField
                  fullWidth
                  label="NRC DE LA SOCIEDAD"
                  name="nrcJuridica"
                  value={this.state.nrcJuridica}
                  onChange={this.handleChangeInput}
                  required
                  variant="outlined"
                  InputProps={{
                    inputComponent: TextMaskNRC
                  }}
                />
              </Grid>
              <Grid
                className={classes.inputContainer}
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  label="TELÉFONO*"
                  id="telefonoJuridica"
                  name="telefonoJuridica"
                  value={this.state.telefonoJuridica}
                  onChange={this.handleChangeInput}
                  InputProps={{
                    inputComponent: TextMaskTelefono
                  }}
                />
              </Grid>
              <Grid
                className={classes.inputContainer}
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
              >
                <TextField
                  fullWidth
                  label="CORREO ELECTRÓNICO DE CONTACTO"
                  placeholder="correo@dominio.com"
                  type="email"
                  name="emailJuridica"
                  value={this.state.emailJuridica}
                  inputProps={{ maxLength: 70, style: { textTransform: 'lowercase' } }}
                  noValidate
                  onChange={this.handleChangeEmailNatural}
                  required
                  variant="outlined"
                  error={this.state.errors.emailJuridicaValid}
                  helperText={this.state.errors.emailJuridica}
                />
              </Grid>
              <Grid
                className={classes.centerControl}
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
              >
                <Typography
                  align="center"
                  variant="h3"
                  className={classes.title}
                >
                  NIT
                </Typography>
                <Typography
                  align="center"
                  variant="caption"
                  className={classes.title}
                  gutterBottom
                >
                  Anexa la fotografía de el NIT de la sociedad
                </Typography>
                <div className={classes.imageBox}>
                  <img
                    className={classes.imageInput}
                    src={base64NITFotoJuridica || defaultImage}
                    ref={img => (this.imgRef = img)}
                  />
                </div>
                <input
                  onChange={this.handleChangePhotoFileInputNitFotoJuridica}
                  ref={input => (this.inputFileRef = input)}
                  style={{ display: "none" }}
                  type="file"
                  id="nit-juridica"
                />
                <label htmlFor="nit-juridica">
                  <IconButton color="primary" className={classes.button} aria-label="subir nit" component="span">
                    <AddPhotoAlternateIcon />
                  </IconButton>
                </label>
              </Grid>
              <Grid
                className={classes.centerControl}
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
              >
                <Typography
                  align="center"
                  variant="h3"
                  className={classes.title}
                >
                  NRC
                </Typography>
                <Typography
                  align="center"
                  variant="caption"
                  className={classes.title}
                  gutterBottom
                >
                  Anexa la fotografía de el NRC de la sociedad
                </Typography>
                <div className={classes.imageBox}>
                  <img
                    className={classes.imageInput}
                    src={base64NRCFotoJuridica || defaultImage}
                    ref={img => (this.imgRef = img)}
                  />
                </div>

                <input
                  onChange={this.handleChangePhotoFileInputNrcFotoJuridica}
                  ref={input => (this.inputFileRef = input)}
                  style={{ display: "none" }}
                  type="file"
                  id="nrc-juridica"
                />
                <label htmlFor="nrc-juridica">
                  <IconButton color="primary" className={classes.button} aria-label="subir nrc" component="span">
                    <AddPhotoAlternateIcon />
                  </IconButton>
                </label>

              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.actionBox}>
            <Button className={classes.backButton} onClick={this.handleClickJuridicaMenu} >
              Regresar
            </Button>
            <Button className={classes.nextButton} onClick={this.handlePersonaJuridica} color="primary">
              Enviar
            </Button>
          </DialogActions>
        </Dialog>
        {/*--------------------------------------------------------------------------------------*/}
      </div>
    )
  }
}
StartButton.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(withStyles(styles)(StartButton));
