import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link, Grid, Hidden, Divider } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
const logoRespondive = process.env.PUBLIC_URL + '/images/logos/logo--white-responsive.svg'
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    background: '#585858'
  },
  logoMovile: {
    height: 95,
    paddingTop: 0,
    paddingBottom: 30
  },
  logo: {
    height: 95,
    paddingTop: 15,
    paddingBottom: 15,
    paddingRight: 60
  },
  logoCenter: {
    textAlign: 'center',
  },
  logoEnd: {
    textAlign: 'end',
  },
  icon: {
    color: '#fff',
    fontSize: 35
  },
  footerText:{
    color:'#fff'
  },
  accessDivider: {
    borderLeft: '1px solid #fff'
  },
  accessSpaceItem: {
    paddingLeft: 60
  }
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Hidden
        mdUp
      >
        <Grid container
          direction="row"
          justify="center"
          alignItems="center">
          <Grid
            className={classes.logoCenter}
            item
            xs={12}
            md={6}
          >
            <Link
              component="a"
              href="https://factotalsv.com/"
            >
              <img
                className={classes.logoMovile}
                alt="Logo"
                src={logoRespondive}
              />
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            <Grid
              container
              wrap="nowrap"
              spacing={2}
              justify="space-evenly"
              alignItems="center"
            >
              <Grid
                item
              >
                <Link
                  component="a"
                  href="https://goo.gl/maps/m6AxQGvZz9w8QX9e6"
                  target="_blank"
                >
                  <RoomIcon
                    className={classes.icon}
                  />
                </Link>
              </Grid>
              <Grid
                item
              >
                <Link
                  component="a"
                  href="tel:25603200"
                >
                  <PhoneIcon
                    className={classes.icon}
                  />
                </Link>
              </Grid>
              <Grid
                item
              >
                
                  <WhatsAppIcon
                    className={classes.icon}
                  />
              </Grid>
              <Grid
                item
              >
                <Link
                  component="a"
                  href="mailto:Info@factotal.com.sv"
                >
                  <MailIcon
                    className={classes.icon}
                  />
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden
        smDown
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid
            className={classes.logoEnd}
            item
            md={6}
          >
            <Link
              component="a"
              href="https://factotalsv.com/"
            >
              <img
                className={classes.logo}
                alt="Logo"
                src={logoRespondive}
              />
            </Link>

          </Grid>
          <Grid
            className={classes.accessDivider}
            item
            md={6}
          >

            <Grid
              className={classes.accessSpaceItem}
              container
              wrap="nowrap"
              spacing={2}
              justify="flex-start"
              alignItems="center"
            >
              <Grid item>
                <Link
                  component="a"
                  href="https://goo.gl/maps/m6AxQGvZz9w8QX9e6"
                  target="_blank"
                >
                  <RoomIcon
                    className={classes.icon}
                  />
                </Link>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography
                className={classes.footerText}
                >
                  Colonia San Benito, Avenida La Capilla No 528,
                </Typography>
                <Typography
                className={classes.footerText}
                >
                  San Salvador, El Salvador
                </Typography>
              </Grid>
            </Grid>

            <Grid
              className={classes.accessSpaceItem}
              container
              wrap="nowrap"
              spacing={2}
              justify="flex-start"
              alignItems="center"
            >
              <Grid item>
                <Link
                  component="a"
                  href="tel:25603200"
                >
                  <PhoneIcon
                    className={classes.icon}
                  />
                </Link>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography
                className={classes.footerText}
                >
                  PBX 2560-3200
                </Typography>
              </Grid>
            </Grid>
            <Grid
              className={classes.accessSpaceItem}
              container
              wrap="nowrap"
              spacing={2}
              justify="flex-start"
              alignItems="center"
            >
              <Grid item>
                
                  <WhatsAppIcon
                    className={classes.icon}
                  />
               
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography
                className={classes.footerText}
                >
                  Atención al Cliente: +503 7919-0333
                </Typography>
              </Grid>
            </Grid>
            <Grid
              className={classes.accessSpaceItem}
              container
              wrap="nowrap"
              spacing={2}
              justify="flex-start"
              alignItems="center"
            >
              <Grid item>
                <Link
                  component="a"
                  href="mailto:Info@factotal.com.sv"
                >
                  <MailIcon
                    className={classes.icon}
                  />
                </Link>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography
                className={classes.footerText}
                >
                  Info@factotal.com.sv
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
