import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { IconButton, Container } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import SearchIcon from '@material-ui/icons/Search';
import LocationInfo from './LocationInfo.json';
import Hidden from '@material-ui/core/Hidden';



const styles = theme => ({
  root: {},
  buttonContainer: {
    width: theme.breakpoints.values.md,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    }
  },
  imageContainerButton: {
    justifyContent: 'center',
    padding: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    }
  },
  imageIcon: {
    width: 120,
    paddingBottom: 15,
    [theme.breakpoints.down('xs')]: {
      width: 80,
    }
  },
  title: {
    color: '#00a196',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20
    }
  },
  fab: {
    position: 'absolute',
    bottom: 16,
    right: 16,
  },
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
  textTop: {
    color: '#009840',
    fontWeight: '500',
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class SearchButton extends Component {

  constructor() {
    super();
    this.state = {
      locationResults: [],
      searchStr: '',
      open: false,
    };


    this.handleSearch = this.handleSearch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  handleSearch(event) {

    this.setState({ searchStr: event.target.value });
    this.state.locationResults = [];

    //add locations to search results
    if (this.state.searchStr.length > 1) {
      for (let i = 0; i < LocationInfo.length; i++) {
        for (let j = 0; j < LocationInfo[i].KeyWords.length; j++) {
          if (LocationInfo[i].KeyWords[j].toLowerCase().indexOf(this.state.searchStr.toLowerCase()) != -1) {
            //check if room is already in the result
            if (!this.state.locationResults.includes(LocationInfo[i].Location))
              this.state.locationResults.push(LocationInfo[i].Location);
          }
        }
      }
    }

  }

  handleSubmit(event) {
    event.preventDefault();
  }
  render() {
    const { classes } = this.props;
    return (

      <div>
        {/*Vista Movil---------------------------------------------------------------------------*/}
        <Hidden mdUp>
          <IconButton aria-label="delete" variant="contained" className={classes.margin} onClick={this.handleClickOpen}>
            <SearchIcon color="primary" />
          </IconButton>
        </Hidden>
        {/*--------------------------------------------------------------------------------------*/}
        {/*Vista Escritorio----------------------------------------------------------------------*/}
        <Hidden smDown>
          <IconButton aria-label="delete" className={classes.margin} onClick={this.handleClickOpen}>
            <SearchIcon fontSize="large" color="primary" />
          </IconButton>
        </Hidden>
        {/*--------------------------------------------------------------------------------------*/}

        <Dialog
          fullScreen
          open={this.state.open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h5" color="inherit" className={classes.flex}>
                Buscar
              </Typography>
            </Toolbar>
          </AppBar>
          <Container>
            <form onSubmit={this.handleSubmit}>
              <TextField
                fullWidth
                id="outlined-name"
                label="Que estas buscando?"
                className={classes.textField}
                value={this.state.searchStr}
                onChange={this.handleSearch}
                margin="normal"
                variant="outlined"
                autoComplete="off"
              />
            </form>
            <div>
              {
                //based upon search result locations display the rooms and their description
                LocationInfo.map((item) => {
                  for (let i = 0; i < this.state.locationResults.length; i++) {
                    if (this.state.locationResults[i] == item.Location) {
                      return (
                        <div>
                          <Divider />
                          <List>
                            <ListItem button component={RouterLink} to={item.href} onClick={this.handleClose}>
                              <ListItemText primary={<Typography variant="h4" className={classes.textTop}>{item.Location}</Typography>} secondary={<Typography variant="body1" >{item.description}</Typography>} />
                            </ListItem>
                          </List>
                        </div>
                      );
                    }
                  }
                })
              }

            </div>
          </Container>
        </Dialog>


      </div>
    )
  }
}
SearchButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SearchButton);
