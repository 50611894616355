import React, { Fragment, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Drawer, Divider, Avatar, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import useRouter from 'utils/useRouter';
import { Navigation } from 'components';
import navigationConfig from './navigationConfig';
import IconButton from '@material-ui/core/IconButton';
const logo = process.env.PUBLIC_URL + '/images/menu/isotype_ft.svg'
const drawerWidth = 256;
const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    overflowY: 'auto'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    overflow: 'hidden'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    minHeight: 99,
    justifyContent: 'space-between',

  },
  content: {
    padding: theme.spacing(2)
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  logo: {
    paddingLeft: 8,
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  },
  navigation: {
    marginTop: theme.spacing(2)
  },
  buttonClose:{
    marginRight: 8
  }
}));

const NavBar = props => {
  const { openMobile, variant, onMobileClose, onClose, className, ...rest } = props;
  const theme = useTheme();
  const classes = useStyles();
  const router = useRouter();

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }
  }, [router.location.pathname]);

  const navbarContent = (
    <div className={classes.content}>     
      <nav className={classes.navigation}>
        {navigationConfig.map(list => (
          <Navigation
            component="div"
            key={list.title}
            pages={list.pages}
          />
        ))}
      </nav>
    </div>
  );

  return (
    <Fragment>
      <Drawer
        anchor="left"
        classes={{ paper: classes.drawer }}
        open={openMobile}
        onClose={onMobileClose}
        variant={variant}
      >
        <div className={classes.drawerHeader}>
          <img
            className={classes.logo}
            alt="Logo"
            src={logo}
          />
          <IconButton onClick={onMobileClose} className={classes.buttonClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <div
          {...rest}
          className={clsx(classes.root, className)}
        >
          {navbarContent}
        </div>
      </Drawer>
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  onClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;