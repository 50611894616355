import uuid from 'uuid/v1';

export default [
  {
    id: uuid(),
    title: 'Gestión de Citas',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    imageUrl: process.env.PUBLIC_URL+'/images/menu/event_note-24px.svg',
    updatedAt: '27/03/2019',
    href: '/quotes',
    go:'_self'
  },
  {
    id: uuid(),
    title: 'Códigos QR',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      imageUrl: process.env.PUBLIC_URL+'/images/menu/qr_code-24px.svg',
    createdAt: '31/03/2019',
    href:'/qr-code',
    go:'_self'
  },
  {
    id: uuid(),
    title: 'Administración',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      imageUrl: process.env.PUBLIC_URL+'/images/menu/admin_panel_settings-24px.svg',
    createdAt: '03/04/2019',
    href: '/setting',
    go:'_self'
  }
];

