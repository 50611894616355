/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import DashboardAnalyticsView from './views/DashboardAnalytics';
import DashboardDefaultView from './views/DashboardDefault';
import OverviewView from './views/Overview';
import DashboardView from './views/DashboardOld';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/home" />
  },  
  {
    path: '/how',
    exact: true,
    component: () => <Redirect to="/how-base" />
  },  
  {
    path: '/sectors',
    exact: true,
    component: () => <Redirect to="/sectors-base" />
  },  
  {
    path: '/requirements',
    exact: true,
    component: () => <Redirect to="/requirements-base" />
  },  
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [      
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/internalError404',
        exact: true,
        component: lazy(() => import('views/InternalError404'))
      },
      {
        path: '/home',
        exact: true,
        component: lazy(() => import('views/Home'))
      }, 
      
      {
        path: '/what-do',
        exact: true,
        component: lazy(() => import('views/WhatDo'))
      }, 
      {
        path: '/how-base',
        exact: true,
        component: lazy(() => import('views/How'))
      }, 
      {
        path: '/requirements-base',
        exact: true,
        component: lazy(() => import('views/Requirements'))
      },
      {
        path: '/sectors-base',
        exact: true,
        component: lazy(() => import('views/Sectors'))
      },  
      {
        path: '/setting',
        exact: true,
        component: lazy(() => import('views/Administration'))
      }, 
      {
        path: '/agencies',
        exact: true,
        component: lazy(() => import('views/Agencies'))
      },  
      {
        path: '/quotes',
        exact: true,
        component: lazy(() => import('views/Quotes'))
      }, 
       
      {
        path: '/qr-code',
        exact: true,
        component: lazy(() => import('views/QRCode'))
      }, 
      {
        path: '/agencies/add',
        exact: true,
        component: lazy(() => import('views/Agencies/AddAgencies'))
      }, 
      {
        path: '/agencies/edit',
        exact: true,
        component: lazy(() => import('views/Agencies/EditAgencies'))
      }, 
      {
        path: '/attention-hours',
        exact: true,
        component: lazy(() => import('views/AttentionHours'))
      },       
      {
        path: '/attention-hours/add',
        exact: true,
        component: lazy(() => import('views/AttentionHours/AddAttentionHours'))
      },       
      {
        path: '/attention-hours/edit',
        exact: true,
        component: lazy(() => import('views/AttentionHours/EditAttentionHours'))
      },     
      {
        path: '/attention-hours/view',
        exact: true,
        component: lazy(() => import('views/AttentionHours/ViewAttentionHours'))
      },
      {
        path: '/operating-position',
        exact: true,
        component: lazy(() => import('views/OperatingPosition'))
      },  
      {
        path: '/operating-position/add',
        exact: true,
        component: lazy(() => import('views/OperatingPosition/AddOperatingPosition'))
      },           
      {
        path: '/operating-position/edit',
        exact: true,
        component: lazy(() => import('views/OperatingPosition/EditOperatingPosition'))
      },         
      {
        path: '/services',
        exact: true,
        component: lazy(() => import('views/Services'))
      },    
      {
        path: '/services/add',
        exact: true,
        component: lazy(() => import('views/Services/AddServices'))
      }, 
      {
        path: '/services/edit',
        exact: true,
        component: lazy(() => import('views/Services/EditServices'))
      },                 
      {
        path: '/agents',
        exact: true,
        component: lazy(() => import('views/Agents'))
      },  
      {
        path: '/agents/add',
        exact: true,
        component: lazy(() => import('views/Agents/AddAgents'))
      }, 
      {
        path: '/agents/edit',
        exact: true,
        component: lazy(() => import('views/Agents/EditAgents'))
      }, 
      {
        path: '/agents/view',
        exact: true,
        component: lazy(() => import('views/Agents/ViewAgents'))
      },  
       
      {
        path: '/operating-flow',
        exact: true,
        component: lazy(() => import('views/OperatingFlow'))
      },   
      {
        path: '/operating-flow/one',
        exact: true,
        component: lazy(() => import('views/OperatingFlow/OperatingFlowOne'))
      },  
      {
        path: '/operating-flow/two',
        exact: true,
        component: lazy(() => import('views/OperatingFlow/OperatingFlowTwo'))
      },   
      {
        path: '/operating-flow/three',
        exact: true,
        component: lazy(() => import('views/OperatingFlow/OperatingFlowThree'))
      },          
      {
        path: '/operating-flow/four',
        exact: true,
        component: lazy(() => import('views/OperatingFlow/OperatingFlowFour'))
      }, 
               
      {
        path: '/operating-flow/five',
        exact: true,
        component: lazy(() => import('views/OperatingFlow/OperatingFlowFive'))
      },          
      {
        path: '/operating-flow/end',
        exact: true,
        component: lazy(() => import('views/OperatingFlow/OperatingFlowEnd'))
      },     
      {
        path: '/dashboard',
        exact: true,
        component: DashboardView
      },







      {
        path: '/calendar',
        exact: true,
        component: lazy(() => import('views/Calendar'))
      },
      {
        path: '/error404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/changelog',
        exact: true,
        component: lazy(() => import('views/Changelog'))
      },
      {
        path: '/chat',
        exact: true,
        component: lazy(() => import('views/Chat'))
      },
      {
        path: '/chat/:id',
        exact: true,
        component: lazy(() => import('views/Chat'))
      },
      {
        path: '/dashboards/analytics',
        exact: true,
        component: DashboardAnalyticsView
      },
      {
        path: '/dashboards/default',
        exact: true,
        component: DashboardDefaultView
      },
      {
        path: '/invoices/:id',
        exact: true,
        component: lazy(() => import('views/InvoiceDetails'))
      },
      {
        path: '/kanban-board',
        exact: true,
        component: lazy(() => import('views/KanbanBoard'))
      },
      {
        path: '/mail',
        exact: true,
        component: lazy(() => import('views/Mail'))
      },
      {
        path: '/management/customers',
        exact: true,
        component: lazy(() => import('views/CustomerManagementList'))
      },
      {
        path: '/management/customers/:id',
        exact: true,
        component: lazy(() => import('views/CustomerManagementDetails'))
      },
      {
        path: '/management/customers/:id/:tab',
        exact: true,
        component: lazy(() => import('views/CustomerManagementDetails'))
      },
      {
        path: '/management/projects',
        exact: true,
        component: lazy(() => import('views/ProjectManagementList'))
      },
      {
        path: '/management/orders',
        exact: true,
        component: lazy(() => import('views/OrderManagementList'))
      },
      {
        path: '/management/orders/:id',
        exact: true,
        component: lazy(() => import('views/OrderManagementDetails'))
      },
      {
        path: '/overview',
        exact: true,
        component: OverviewView
      },
      
      {
        path: '/profile/:id',
        exact: true,
        component: lazy(() => import('views/Profile'))
      },
      {
        path: '/profile/:id/:tab',
        exact: true,
        component: lazy(() => import('views/Profile'))
      },
      {
        path: '/projects/create',
        exact: true,
        component: lazy(() => import('views/ProjectCreate'))
      },
      {
        path: '/projects/:id',
        exact: true,
        component: lazy(() => import('views/ProjectDetails'))
      },
      {
        path: '/projects/:id/:tab',
        exact: true,
        component: lazy(() => import('views/ProjectDetails'))
      },
      {
        path: '/projects',
        exact: true,
        component: lazy(() => import('views/ProjectList'))
      },     
      {
        path: '/social-feed',
        exact: true,
        component: lazy(() => import('views/SocialFeed'))
      },
      {
        path: '/getting-started',
        exact: true,
        component: lazy(() => import('views/GettingStarted'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];

export default routes;
